import { DataGrid } from '@mui/x-data-grid';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import Link from '@mui/material/Link';
import { FileModel } from '../models/FileModel';

export default function CustomizedDataGrid({files}: {files: FileModel[]}) {
  console.log(files);
  return (
    <DataGrid
    rows={files}
    columns={[
      {
        field: 'filename',
        headerName: 'Filename',
        flex: 1.5,
        minWidth: 200,
      },
      {
        field: 'version',
        headerName: 'Version',
        flex: 0.5,
        minWidth: 100,
      },
      {
        field: 'mimeType',
        headerName: 'MIME Type',
        flex: 1.2,
        minWidth: 150,
      },
      {
        field: 'createdAt',
        headerName: 'Created At',
        flex: 2,
        minWidth: 150,
      },
      {
        field: 'downloadUrl',
        headerName: 'Download',
        flex: 0.5,
        minWidth: 30,
        renderCell: (params) => (
          <Link href={params.value as string} target="_blank" rel="noopener noreferrer">
          <FileDownloadOutlinedIcon />
          </Link>
        ),
      },
    ]}
    disableColumnResize
    density="compact"
    hideFooter={true}
    />
  );
}
