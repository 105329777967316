import * as React from 'react';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CustomizedDataGrid from './CustomizedDataGrid';
import { useFileController } from '../controller/File';
import Button from '@mui/material/Button';
import { useState } from 'react';
import { FileModel } from '../models/FileModel';
import { FileService } from '../services/File';
import { useNavigate } from 'react-router-dom';
import AddFileDialog from './AddFileDialog';

export default function MainGrid() {
  const { files, handleLoadFiles } = useFileController();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  React.useEffect(() => {
    handleLoadFiles();
  }, []);

  const handleDialogOpen = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);

  const handleFileSubmit = async (
    file: Omit<FileModel, 'id' | 'downloadUrl' | 'mimeType' | 'createdAt'>
  ) => {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const token = user.token;
    try {
      await FileService.addFile(file, token);
      alert('File added successfully!');
      handleDialogClose();
      await handleLoadFiles();
      navigate('/');
    } catch (error) {
      console.error('Error adding File:', error);
    }
  };

  return (
    <Box sx={{ width: '100%', maxWidth: { sm: '100%', md: '1700px' } }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 2,
        }}
      >
        <Typography component="h2" variant="h6">
          Ota Files
        </Typography>
        <Button variant="contained" onClick={handleDialogOpen}>
          Add File
        </Button>
      </Box>
      <Grid>
        <CustomizedDataGrid files={files} />
      </Grid>

      {/* Dialog Component */}
      <AddFileDialog open={open} onClose={handleDialogClose} onSubmit={handleFileSubmit} />
    </Box>
  );
}
