import axios from "axios";
import { API_URL } from "../utils/url";
import { FileModel } from "../models/FileModel"

export const FileService = {
    fetchFiles: async () => {
        try {
            const user = JSON.parse(localStorage.getItem('user') || '{}');
            const token = user.token;
            const response = await axios.get(`${API_URL}/files/list`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            return response.data;
        }
        catch (e) {
            throw new Error('Failed to get files');
        }
    },
    addFile: async (file: Omit<FileModel, 'id' | 'downloadUrl' | 'mimeType' | 'createdAt'>, token: string): Promise<void> => {
        try{
            const formData = new FormData();
            formData.append('fileName', file.filename);
            formData.append('version', file.version);
            if (file.otaFile) {
                formData.append('file', file.otaFile);
            }
            const response = await fetch(`${API_URL}/files/upload`, {
                method: 'POST',
                headers: {
                    'Authorization': `Token ${token}`,
                },
                body: formData,
            });
            if (!response.ok) {
                throw new Error('Failed to add file');
            }
        }
        catch(e){
            throw new Error('Failed to add file');
        }
    }
}