import { useState } from "react";
import { FileService } from "../services/File";
import { FileModel } from "../models/FileModel"

export const useFileController = () => {
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState<FileModel[]>([]);
  
  const handleLoadFiles = async () => {
    setLoading(true);
    try {
      const response = await FileService.fetchFiles();
      setFiles(response);
    } catch (error) {
      alert(error);
    } finally {
      setLoading(false);
    }
  };
  
  return {
    loading,
    files,
    setLoading,
    handleLoadFiles,
  }
}