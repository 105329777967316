import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Header from '../components/Header';
import MainGrid from '../components/MainGrid';
import AppTheme from '../theme/AppTheme';


export default function HomePage(props: { disableCustomTheme?: boolean }) {
  
  return (
    <AppTheme {...props}>
    <CssBaseline enableColorScheme />
    <Box sx={{ display: 'flex' }}>
    <Box
    component="main"
    sx={(theme) => ({
      flexGrow: 1,
      backgroundColor: `rgba(${theme.palette.background} / 1)`,
      overflow: 'auto',
    })}
    >
    <Stack
    spacing={2}
    sx={{
      alignItems: 'center',
      mx: 3,
      pb: 5,
      mt: { xs: 8, md: 0 },
    }}
    >
    <Header />
    <MainGrid />
    </Stack>
    </Box>
    </Box>
    </AppTheme>
  );
}
