import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import MuiCard from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import AppTheme from '../theme/AppTheme';
import loginImage from '../assets/images/LoginImage.svg';
import { useLoginController } from '../controller/Login';
import { useState } from 'react';

const Card = styled(MuiCard)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  width: '100%',
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  margin: 'auto',
  [theme.breakpoints.up('sm')]: {
    maxWidth: '450px',
  },
  boxShadow:
  'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
  ...theme.applyStyles('dark', {
    boxShadow:
    'hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px',
  }),
}));

const SignInContainer = styled(Stack)(({ theme }) => ({
  height: 'calc((1 - var(--template-frame-height, 0)) * 100dvh)',
  minHeight: '100%',
  padding: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(4),
  },
  '&::before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    zIndex: -1,
    inset: 0,
    backgroundImage:
    'radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))',
    backgroundRepeat: 'no-repeat',
    ...theme.applyStyles('dark', {
      backgroundImage:
      'radial-gradient(at 50% 50%, hsla(210, 100%, 16%, 0.5), hsl(220, 30%, 5%))',
    }),
  },
}));

export default function SignIn(props: { disableCustomTheme?: boolean }) {
  const [usernameError, setUsernameError] = React.useState(false);
  const [usernameErrorMessage, setUsernameErrorMessage] = React.useState('');
  const [passwordError, setPasswordError] = React.useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = React.useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const { loading, handleLogin } = useLoginController(username, password);
  
  
  const validateInputs = () => {
    const username = document.getElementById('username') as HTMLInputElement;
    const password = document.getElementById('password') as HTMLInputElement;
    
    let isValid = true;
    
    if (!username.value) {
      setUsernameError(true);
      setUsernameErrorMessage('Please enter a valid username address.');
      isValid = false;
    } else {
      setUsernameError(false);
      setUsernameErrorMessage('');
    }
    
    if (!password.value) {
      setPasswordError(true);
      setPasswordErrorMessage('Password must be at least 6 characters long.');
      isValid = false;
    } else {
      setPasswordError(false);
      setPasswordErrorMessage('');
    }
    
    return isValid;
  };
  
  return (
    <AppTheme {...props}>
    <CssBaseline enableColorScheme />
    <SignInContainer direction="column" justifyContent="space-between">
    <Card variant="outlined">
    <img src={loginImage} alt="Login Illustration" style={{ maxWidth: '100px' }} />
    <Typography
    component="h1"
    variant="h4"
    sx={{ width: '100%', fontSize: 'clamp(2rem, 10vw, 2.15rem)' }}
    >
    Log in
    </Typography>
    <Box
    component="form"
    onSubmit={handleLogin}
    noValidate
    sx={{
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      gap: 2,
    }}
    >
    <FormControl>
    <FormLabel htmlFor="username">Username</FormLabel>
    <TextField
    onChange={(e) => setUsername(e.target.value)}
    error={usernameError}
    helperText={usernameErrorMessage}
    id="username"
    type="username"
    name="username"
    placeholder="username"
    autoFocus
    required
    fullWidth
    variant="outlined"
    color={usernameError ? 'error' : 'primary'}
    />
    </FormControl>
    <FormControl>
    <FormLabel htmlFor="password">Password</FormLabel>
    <TextField
    onChange={(e) => setPassword(e.target.value)}
    error={passwordError}
    helperText={passwordErrorMessage}
    name="password"
    placeholder="••••••"
    type="password"
    id="password"
    autoFocus
    required
    fullWidth
    variant="outlined"
    color={passwordError ? 'error' : 'primary'}
    />
    </FormControl>
    {loading ?
      <Button
      fullWidth
      variant="contained"
      >
      Sign in
      </Button>
      : (
        <Button
        type="submit"
        fullWidth
        variant="contained"
        onClick={validateInputs}
        >
        Sign in
        </Button>
      )}
      </Box>
      
      </Card>
      </SignInContainer>
      </AppTheme>
    );
  }
  