import Stack from '@mui/material/Stack';
import LogoutIcon from '@mui/icons-material/Logout';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { useAuth } from '../hooks/useAuth';


export default function Header() {
  
  const { logout } = useAuth();
  
  const handleLogout = () => {
    localStorage.removeItem('user');
    logout(); 
  };
  
  return (
    <Stack
    direction="row"
    sx={{
      display: { xs: 'none', md: 'flex' },
      width: '100%',
      alignItems: { xs: 'flex-start', md: 'center' },
      justifyContent: 'space-between',
      maxWidth: { sm: '100%', md: '1700px' },
      pt: 1.5,
    }}
    spacing={2}
    >
    <Typography>
    Dashboard
    </Typography>
    <Stack direction="row" sx={{ gap: 1 }}>
    <IconButton
    size="small"
    aria-label="Logout"
    onClick={handleLogout}>
    <LogoutIcon />
    </IconButton>
    </Stack>
    </Stack>
  );
}
