import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { FileModel } from '../models/FileModel';
import FormLabel from '@mui/material/FormLabel';

interface AddFileDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (file: Omit<FileModel, 'id' | 'downloadUrl' | 'mimeType' | 'createdAt'>) => void;
}

const AddFileDialog: React.FC<AddFileDialogProps> = ({ open, onClose, onSubmit }) => {
  const [file, setFile] = useState<Omit<FileModel, 'id' | 'downloadUrl' | 'mimeType' | 'createdAt'>>({
    filename: '',
    version: '',
    otaFile: null,
  });

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length > 0) {
        setFile((prevFile) => ({
        ...prevFile,
        otaFile: files[0],
        }));
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(file);
    setFile({
      filename: '',
      version: '',
      otaFile: null,
    });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add File</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <FormLabel htmlFor="filename">File Name</FormLabel>
          <TextField
            fullWidth
            id="filename"
            placeholder="filename"
            name="filename"
            margin="dense"
            variant="outlined"
            value={file.filename}
            onChange={(e) =>
              setFile((prevFile) => ({
                ...prevFile,
                filename: e.target.value,
              }))
            }
            required
          />
          <FormLabel htmlFor="version">Version</FormLabel>
          <TextField
            fullWidth
            id="version"
            placeholder="version"
            name="version"
            margin="dense"
            variant="outlined"
            value={file.version}
            onChange={(e) =>
              setFile((prevFile) => ({
                ...prevFile,
                version: e.target.value,
              }))
            }
            required
          />
          <Button variant="contained" component="label" sx={{ mt: 2 }}>
            Upload File
            <input type="file" hidden onChange={handleFileUpload} />
          </Button>
          {file.otaFile && (
            <Typography variant="body2" sx={{ mt: 1 }}>
              File selected: {file.otaFile.name}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="submit" variant="contained" color="primary">
            Submit
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddFileDialog;
